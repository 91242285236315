var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { attrs: { name: "formPayBoxClose" } },
        [
          _c(
            "b-card-actions",
            { attrs: { "no-actions": "", title: _vm.$t("Resumo") } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "caixa",
                          label: _vm.$t("Caixa"),
                          value: _vm.cashBookDetail.payBox.number,
                          type: "label",
                          filter: "padLeft",
                          "filter-params": [2, "0"],
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "loja",
                          label: _vm.$t("Loja"),
                          value: _vm.cashBookDetail.payBox.store,
                          type: "label",
                          filter: "storeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "data-abertura",
                          label: _vm.$t("Abertura"),
                          value:
                            _vm.$options.filters.datetime(
                              _vm.cashBookDetail.openingDate
                            ) +
                            " / " +
                            (_vm.cashBookDetail.openingUser
                              ? _vm.cashBookDetail.openingUser.name
                              : "-"),
                          type: "label",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "data-fechamento",
                          label: _vm.$t("Fechamento"),
                          value:
                            _vm.$options.filters.datetime(
                              _vm.cashBookDetail.closingDate
                            ) +
                            " / " +
                            (_vm.cashBookDetail.closingUser
                              ? _vm.cashBookDetail.closingUser.name
                              : "-"),
                          type: "label",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          label: _vm.$t("Status"),
                          type: "status-badge",
                          "true-label": _vm.$t("Aberto"),
                          "false-label": _vm.$t("Fechado"),
                        },
                        model: {
                          value: _vm.isOpen,
                          callback: function ($$v) {
                            _vm.isOpen = $$v
                          },
                          expression: "isOpen",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isOpen
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "fechamento-forcado",
                              label: _vm.$t("Com diferença?"),
                              type: "status-badge",
                              "badge-type": "yesNo",
                            },
                            model: {
                              value: _vm.cashBookDetail.closingForce,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cashBookDetail,
                                  "closingForce",
                                  $$v
                                )
                              },
                              expression: "cashBookDetail.closingForce",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isOpen && _vm.cashBookDetail.closingForce
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "tipo-diferenca",
                              value:
                                (_vm.isDiferenceCredit
                                  ? _vm.$t("Sobra")
                                  : _vm.$t("Falta")) +
                                " /\n            " +
                                (_vm.cashBookDetail.closingForceUser
                                  ? _vm.$t("Autorizado por ") +
                                    _vm.cashBookDetail.closingForceUser.name
                                  : "-"),
                              label: _vm.$t("Tipo de diferença"),
                              type: "label",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isOpen && _vm.cashBookDetail.closingForce
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            class: _vm.isDiferenceCredit
                              ? "text-success"
                              : "text-danger",
                            attrs: {
                              id: "valor-diferenca",
                              value: Math.abs(
                                _vm.cashBookDetail.closingBalance
                              ),
                              label: _vm.$t("Valor da diferença"),
                              type: "label",
                              filter: "currency",
                              instructions: [
                                {
                                  text: _vm.$t(
                                    "Valor de diferença calculado para todos os lançamentos do caixa, incluindo dinheiro e outras formas de pagamento"
                                  ),
                                },
                                {
                                  text: _vm.$t(
                                    "Quando 'Falta' um contas a receber foi criado para o operador do caixa"
                                  ),
                                },
                                {
                                  text: _vm.$t(
                                    "Quando 'Sobra' o valor foi direcionado para conta cofre da loja"
                                  ),
                                },
                              ],
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isOpen && _vm.cashBookDetail.closingForce
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "justificativa",
                              value: _vm.cashBookDetail.closingObservation,
                              label: _vm.$t("Justificativa"),
                              type: "label",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isOpen
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: { "no-actions": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "p",
                                      { staticClass: "h4 mb-0 text-info" },
                                      [_vm._v(" Total das Entradas ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3396558178
                          ),
                        },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "container-box" },
                              [
                                _c("box-line", {
                                  attrs: {
                                    "is-header": "",
                                    "show-estimated-value": true,
                                  },
                                }),
                                _vm._l(
                                  _vm.localSummaryCredit,
                                  function (credit) {
                                    return _c(
                                      "box-line",
                                      {
                                        key:
                                          credit.origin +
                                          "_" +
                                          credit.paymentMethod.id,
                                        attrs: {
                                          title: _vm.getCreditLabel(credit),
                                          "estimated-value":
                                            credit.totalValueExpected,
                                          "show-estimated-value": true,
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            class: {
                                              h5: true,
                                              "text-dark":
                                                credit.totalValueExpected ===
                                                credit.totalValueInformed,
                                              "text-success":
                                                credit.totalValueExpected <
                                                credit.totalValueInformed,
                                              "text-danger":
                                                credit.totalValueExpected >
                                                credit.totalValueInformed,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    credit.totalValueInformed
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _c(
                                  "box-line",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      title: "Total",
                                      "show-estimated-value": true,
                                      "estimated-value":
                                        _vm.getTotalsEstimated.credit,
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class: {
                                          h5: true,
                                          "text-dark":
                                            _vm.getTotalsEstimated.credit ===
                                            _vm.getTotalsInformed.credit,
                                          "text-success":
                                            _vm.getTotalsEstimated.credit <
                                            _vm.getTotalsInformed.credit,
                                          "text-danger":
                                            _vm.getTotalsEstimated.credit >
                                            _vm.getTotalsInformed.credit,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.getTotalsInformed.credit
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "box-line",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      title: "Total [Em dinheiro]",
                                      "show-estimated-value": true,
                                      "estimated-value":
                                        _vm.getTotalsEstimated.creditCash,
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "h5 text-dark" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.getTotalsInformed.creditCash
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: { "no-actions": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "p",
                                      { staticClass: "h4 mb-0 text-danger" },
                                      [_vm._v(" Total das Saídas ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1694666180
                          ),
                        },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "container-box" },
                              [
                                _c("box-line", {
                                  attrs: {
                                    "is-header": "",
                                    "show-estimated-value": true,
                                  },
                                }),
                                _vm._l(_vm.localSummaryDebit, function (debit) {
                                  return _c(
                                    "box-line",
                                    {
                                      key:
                                        debit.origin +
                                        "_" +
                                        debit.paymentMethod.id,
                                      attrs: {
                                        title:
                                          _vm.saleOriginEnumLabel[
                                            debit.origin
                                          ] +
                                          " [" +
                                          debit.paymentMethod.name +
                                          "]",
                                        "estimated-value":
                                          debit.totalValueExpected,
                                        "show-estimated-value": true,
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            h5: true,
                                            "text-dark":
                                              debit.totalValueExpected ===
                                              debit.totalValueInformed,
                                            "text-success":
                                              debit.totalValueExpected <
                                              debit.totalValueInformed,
                                            "text-danger":
                                              debit.totalValueExpected >
                                              debit.totalValueInformed,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  debit.totalValueInformed
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                _c(
                                  "box-line",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      title: "Total",
                                      "show-estimated-value": true,
                                      "estimated-value":
                                        _vm.getTotalsEstimated.debit,
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class: {
                                          h5: true,
                                          "text-dark":
                                            _vm.getTotalsEstimated.debit ===
                                            _vm.getTotalsInformed.debit,
                                          "text-success":
                                            _vm.getTotalsEstimated.debit <
                                            _vm.getTotalsInformed.debit,
                                          "text-danger":
                                            _vm.getTotalsEstimated.debit >
                                            _vm.getTotalsInformed.debit,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.getTotalsInformed.debit
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "box-line",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      title: "Total [Em dinheiro]",
                                      "show-estimated-value": true,
                                      "estimated-value":
                                        _vm.getTotalsEstimated.debitCash,
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "h5 text-dark" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.getTotalsInformed.debitCash
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isOpen
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: { "no-actions": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "p",
                                      { staticClass: "h4 mb-0 text-primary" },
                                      [_vm._v(" Saldo Caixa [Em Dinheiro] ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            749475325
                          ),
                        },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "container-box" },
                              [
                                _c("box-line", {
                                  attrs: {
                                    "is-header": "",
                                    "show-estimated-value": true,
                                  },
                                }),
                                _c(
                                  "box-line",
                                  {
                                    attrs: {
                                      title: _vm.$t("Troco inicial caixa"),
                                    },
                                  },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_close-opening_balance",
                                        name: "openingBalance",
                                        filter: "currency",
                                        type: "label",
                                        "input-class": "text-right",
                                      },
                                      model: {
                                        value: _vm.openingBalance,
                                        callback: function ($$v) {
                                          _vm.openingBalance = $$v
                                        },
                                        expression: "openingBalance",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "box-line",
                                  {
                                    attrs: {
                                      title: _vm.$t("Troco final caixa"),
                                      "show-estimated-value": true,
                                      "estimated-value":
                                        _vm.getTotalsEstimated
                                          .finalPayBoxChange,
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class: {
                                          h5: true,
                                          "text-dark":
                                            _vm.getTotalsEstimated
                                              .finalPayBoxChangeValue ===
                                            _vm.cashBookDetail
                                              .finalPayBoxChangeValue,
                                          "text-success":
                                            _vm.getTotalsEstimated
                                              .finalPayBoxChangeValue <
                                            _vm.cashBookDetail
                                              .finalPayBoxChangeValue,
                                          "text-danger":
                                            _vm.getTotalsEstimated
                                              .finalPayBoxChangeValue >
                                            _vm.cashBookDetail
                                              .finalPayBoxChangeValue,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.cashBookDetail
                                                  .finalPayBoxChangeValue
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "box-line",
                                  {
                                    attrs: {
                                      title: _vm.$t("Sangria Final"),
                                      "show-estimated-value": true,
                                      "estimated-value":
                                        _vm.getTotalsEstimated
                                          .finalBleedingValue,
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class: {
                                          h5: true,
                                          "text-dark":
                                            _vm.getTotalsEstimated
                                              .finalBleedingValue ===
                                            _vm.cashBookDetail
                                              .finalBleedingValue,
                                          "text-success":
                                            _vm.getTotalsEstimated
                                              .finalBleedingValue <
                                            _vm.cashBookDetail
                                              .finalBleedingValue,
                                          "text-danger":
                                            _vm.getTotalsEstimated
                                              .finalBleedingValue >
                                            _vm.cashBookDetail
                                              .finalBleedingValue,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.cashBookDetail
                                                  .finalBleedingValue
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "box-line",
                                  {
                                    attrs: {
                                      title: "Saldo final caixa",
                                      "show-estimated-value": true,
                                      "estimated-value":
                                        _vm.getTotalsEstimated.cashTotalBalance,
                                      instruction: _vm.$t(
                                        "CASH_BOOK.CLOSE.UI.FINAL_BALANCE"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        class: {
                                          h5: true,
                                          "text-dark":
                                            _vm.cashBookDetail
                                              .closingBalanceCash === 0,
                                          "text-success":
                                            _vm.cashBookDetail
                                              .closingBalanceCash < 0,
                                          "text-danger":
                                            _vm.cashBookDetail
                                              .closingBalanceCash > 0,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.cashBookDetail
                                                  .closingBalanceCash
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "pb-2" },
            [
              _c(
                "b-col",
                [
                  _c("e-button", {
                    attrs: { text: _vm.$t("Voltar"), block: "" },
                    on: {
                      click: function () {
                        return _vm.$router.back()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }