<template>
  <section>
    <FormulateForm name="formPayBoxClose">
      <b-card-actions
        no-actions
        :title="$t('Resumo')"
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="caixa"
              :label="$t('Caixa')"
              :value="cashBookDetail.payBox.number"
              type="label"
              filter="padLeft"
              :filter-params="[2, '0']"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="loja"
              :label="$t('Loja')"
              :value="cashBookDetail.payBox.store"
              type="label"
              filter="storeName"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="data-abertura"
              :label="$t('Abertura')"
              :value="`${$options.filters.datetime(cashBookDetail.openingDate)} / ${
                cashBookDetail.openingUser ? cashBookDetail.openingUser.name : '-'
              }`"
              type="label"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="data-fechamento"
              :label="$t('Fechamento')"
              :value="`${$options.filters.datetime(cashBookDetail.closingDate)} / ${
                cashBookDetail.closingUser ? cashBookDetail.closingUser.name : '-'
              }`"
              type="label"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="isOpen"
              :label="$t('Status')"
              type="status-badge"
              :true-label="$t('Aberto')"
              :false-label="$t('Fechado')"
            />
          </b-col>
          <b-col
            v-if="!isOpen"
            md="2"
          >
            <FormulateInput
              id="fechamento-forcado"
              v-model="cashBookDetail.closingForce"
              :label="$t('Com diferença?')"
              type="status-badge"
              badge-type="yesNo"
            />
          </b-col>
          <b-col
            v-if="!isOpen && cashBookDetail.closingForce"
            md="2"
          >
            <FormulateInput
              id="tipo-diferenca"
              :value="`${isDiferenceCredit ? $t('Sobra') : $t('Falta')} /
              ${
                cashBookDetail.closingForceUser
                  ? $t('Autorizado por ') + cashBookDetail.closingForceUser.name
                  : '-'
              }`"
              :label="$t('Tipo de diferença')"
              type="label"
            />
          </b-col>
          <b-col
            v-if="!isOpen && cashBookDetail.closingForce"
            md="2"
          >
            <FormulateInput
              id="valor-diferenca"
              :value="Math.abs(cashBookDetail.closingBalance)"
              :label="$t('Valor da diferença')"
              :class="isDiferenceCredit ? 'text-success' : 'text-danger'"
              type="label"
              filter="currency"
              :instructions="[
                {
                  text: $t(
                    'Valor de diferença calculado para todos os lançamentos do caixa, incluindo dinheiro e outras formas de pagamento'
                  ),
                },
                {
                  text: $t(
                    'Quando \'Falta\' um contas a receber foi criado para o operador do caixa'
                  ),
                },
                { text: $t('Quando \'Sobra\' o valor foi direcionado para conta cofre da loja') },
              ]"
            />
          </b-col>
          <b-col
            v-if="!isOpen && cashBookDetail.closingForce"
            md="2"
          >
            <FormulateInput
              id="justificativa"
              :value="cashBookDetail.closingObservation"
              :label="$t('Justificativa')"
              type="label"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-row v-if="!isOpen">
        <b-col md="6">
          <b-card-actions no-actions>
            <template #title>
              <p class="h4 mb-0 text-info">
                Total das Entradas
              </p>
            </template>

            <b-col md="12">
              <div class="container-box">
                <box-line
                  is-header
                  :show-estimated-value="true"
                />

                <box-line
                  v-for="credit in localSummaryCredit"
                  :key="`${credit.origin}_${credit.paymentMethod.id}`"
                  :title="getCreditLabel(credit)"
                  :estimated-value="credit.totalValueExpected"
                  :show-estimated-value="true"
                >
                  <p
                    :class="{
                      h5: true,
                      'text-dark': credit.totalValueExpected === credit.totalValueInformed,
                      'text-success': credit.totalValueExpected < credit.totalValueInformed,
                      'text-danger': credit.totalValueExpected > credit.totalValueInformed,
                    }"
                  >
                    {{ credit.totalValueInformed | currency }}
                  </p>
                </box-line>

                <box-line
                  :title="'Total'"
                  class="font-weight-bold"
                  :show-estimated-value="true"
                  :estimated-value="getTotalsEstimated.credit"
                >
                  <p
                    :class="{
                      h5: true,
                      'text-dark': getTotalsEstimated.credit === getTotalsInformed.credit,
                      'text-success': getTotalsEstimated.credit < getTotalsInformed.credit,
                      'text-danger': getTotalsEstimated.credit > getTotalsInformed.credit,
                    }"
                  >
                    {{ getTotalsInformed.credit | currency }}
                  </p>
                </box-line>

                <box-line
                  :title="'Total [Em dinheiro]'"
                  class="font-weight-bold"
                  :show-estimated-value="true"
                  :estimated-value="getTotalsEstimated.creditCash"
                >
                  <p class="h5 text-dark">
                    {{ getTotalsInformed.creditCash | currency }}
                  </p>
                </box-line>
              </div>
            </b-col>
          </b-card-actions>
        </b-col>

        <b-col md="6">
          <b-card-actions no-actions>
            <template #title>
              <p class="h4 mb-0 text-danger">
                Total das Saídas
              </p>
            </template>

            <b-col md="12">
              <div class="container-box">
                <box-line
                  is-header
                  :show-estimated-value="true"
                />

                <box-line
                  v-for="debit in localSummaryDebit"
                  :key="`${debit.origin}_${debit.paymentMethod.id}`"
                  :title="`${saleOriginEnumLabel[debit.origin]} [${debit.paymentMethod.name}]`"
                  :estimated-value="debit.totalValueExpected"
                  :show-estimated-value="true"
                >
                  <p
                    :class="{
                      h5: true,
                      'text-dark': debit.totalValueExpected === debit.totalValueInformed,
                      'text-success': debit.totalValueExpected < debit.totalValueInformed,
                      'text-danger': debit.totalValueExpected > debit.totalValueInformed,
                    }"
                  >
                    {{ debit.totalValueInformed | currency }}
                  </p>
                </box-line>

                <box-line
                  :title="'Total'"
                  class="font-weight-bold"
                  :show-estimated-value="true"
                  :estimated-value="getTotalsEstimated.debit"
                >
                  <p
                    :class="{
                      h5: true,
                      'text-dark': getTotalsEstimated.debit === getTotalsInformed.debit,
                      'text-success': getTotalsEstimated.debit < getTotalsInformed.debit,
                      'text-danger': getTotalsEstimated.debit > getTotalsInformed.debit,
                    }"
                  >
                    {{ getTotalsInformed.debit | currency }}
                  </p>
                </box-line>

                <box-line
                  :title="'Total [Em dinheiro]'"
                  class="font-weight-bold"
                  :show-estimated-value="true"
                  :estimated-value="getTotalsEstimated.debitCash"
                >
                  <p class="h5 text-dark">
                    {{ getTotalsInformed.debitCash | currency }}
                  </p>
                </box-line>
              </div>
            </b-col>
          </b-card-actions>
        </b-col>
      </b-row>

      <b-row v-if="!isOpen">
        <b-col>
          <b-card-actions no-actions>
            <template #title>
              <p class="h4 mb-0 text-primary">
                Saldo Caixa [Em Dinheiro]
              </p>
            </template>

            <b-col md="12">
              <div class="container-box">
                <box-line
                  is-header
                  :show-estimated-value="true"
                />

                <box-line :title="$t('Troco inicial caixa')">
                  <FormulateInput
                    id="pay_box_close-opening_balance"
                    v-model="openingBalance"
                    name="openingBalance"
                    filter="currency"
                    type="label"
                    input-class="text-right"
                  />
                </box-line>

                <box-line
                  :title="$t('Troco final caixa')"
                  :show-estimated-value="true"
                  :estimated-value="getTotalsEstimated.finalPayBoxChange"
                >
                  <p
                    :class="{
                      h5: true,
                      'text-dark':
                        getTotalsEstimated.finalPayBoxChangeValue ===
                        cashBookDetail.finalPayBoxChangeValue,
                      'text-success':
                        getTotalsEstimated.finalPayBoxChangeValue <
                        cashBookDetail.finalPayBoxChangeValue,
                      'text-danger':
                        getTotalsEstimated.finalPayBoxChangeValue >
                        cashBookDetail.finalPayBoxChangeValue,
                    }"
                  >
                    {{ cashBookDetail.finalPayBoxChangeValue | currency }}
                  </p>
                </box-line>

                <box-line
                  :title="$t('Sangria Final')"
                  :show-estimated-value="true"
                  :estimated-value="getTotalsEstimated.finalBleedingValue"
                >
                  <p
                    :class="{
                      h5: true,
                      'text-dark':
                        getTotalsEstimated.finalBleedingValue === cashBookDetail.finalBleedingValue,
                      'text-success':
                        getTotalsEstimated.finalBleedingValue < cashBookDetail.finalBleedingValue,
                      'text-danger':
                        getTotalsEstimated.finalBleedingValue > cashBookDetail.finalBleedingValue,
                    }"
                  >
                    {{ cashBookDetail.finalBleedingValue | currency }}
                  </p>
                </box-line>

                <box-line
                  :title="'Saldo final caixa'"
                  :show-estimated-value="true"
                  :estimated-value="getTotalsEstimated.cashTotalBalance"
                  :instruction="$t('CASH_BOOK.CLOSE.UI.FINAL_BALANCE')"
                >
                  <p
                    :class="{
                      h5: true,
                      'text-dark': cashBookDetail.closingBalanceCash === 0,
                      'text-success': cashBookDetail.closingBalanceCash < 0,
                      'text-danger': cashBookDetail.closingBalanceCash > 0,
                    }"
                  >
                    {{ cashBookDetail.closingBalanceCash | currency }}
                  </p>
                </box-line>
              </div>
            </b-col>
          </b-card-actions>
        </b-col>
      </b-row>

      <b-row class="pb-2">
        <b-col>
          <e-button
            :text="$t('Voltar')"
            block
            @click="() => $router.back()"
          />
        </b-col>
      </b-row>
    </FormulateForm>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { loadingOverlay, payBoxTypes, paymentTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex/'
import BoxLine from './components/BoxLine.vue'

export default {
  name: 'PayBoxClose',
  components: {
    BRow,
    BCol,
    BoxLine,
    BCardActions,
    EButton,
  },

  mixins: [paymentTypes, payBoxTypes, loadingOverlay],

  data() {
    return {
      delegateUserData: null,
      localSummaryCredit: [],
      localSummaryDebit: [],
      totalsTyped: {
        credit: 0,
        creditCash: 0,
        debit: 0,
        debitCash: 0,
      },
      openingBalance: 0,
      finalPayBoxChange: 0,
      cashTotalBalance: 0,
      finalBleeding: 0,
      forcedForm: {
        observation: '',
      },
      isForcedClose: false,
    }
  },

  computed: {
    ...mapState('pages/pdv', ['cashBookDetail']),
    ...mapGetters('pages/pdv', ['summaryCreditsFormatted', 'summaryDebitsFormatted']),
    isDiferenceCredit() {
      // no caixa a lógica é se for negativo é sobra, e falta positivo
      return this.cashBookDetail.closingBalance < 0
    },

    getTotalsEstimated() {
      const {
        summaryCredits,
        summaryDebits,
        closingBalanceCashExpected,
        finalBleedingValueExpected,
        finalPayBoxChangeValueExpected,
      } = this.cashBookDetail

      const credit = summaryCredits.reduce((total, c) => total + c.totalValueExpected, 0)

      const creditCash = summaryCredits
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, c) => total + c.totalValueExpected, 0)

      const debit = summaryDebits.reduce((total, d) => total + d.totalValueExpected, 0)
      const debitCash = summaryDebits
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, d) => total + d.totalValueExpected, 0)

      return {
        credit,
        creditCash,
        debit,
        debitCash,
        finalPayBoxChange: finalPayBoxChangeValueExpected,
        cashTotalBalance: closingBalanceCashExpected,
        finalBleedingValue: finalBleedingValueExpected,
      }
    },

    getTotalsInformed() {
      const { summaryCredits, summaryDebits, openingBalance, finalBleedingValue } =
        this.cashBookDetail

      const credit = summaryCredits.reduce((total, c) => total + c.totalValueInformed, 0)

      const creditCash = summaryCredits
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, c) => total + c.totalValueInformed, 0)

      const debit = summaryDebits.reduce((total, d) => total + d.totalValueInformed, 0)
      const debitCash = summaryDebits
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, d) => total + d.totalValueInformed, 0)

      const cashTotalBalance =
        creditCash - (debitCash + finalBleedingValue) + (openingBalance - this.finalPayBoxChange)

      return {
        credit,
        creditCash,
        debit,
        debitCash,
        finalPayBoxChange: openingBalance,
        cashTotalBalance,
      }
    },

    isOpen() {
      return !this.cashBookDetail.closingDate
    },
  },

  async mounted() {
    try {
      this.showLoadingOverlay()
      await this.stFetchCashBookDetailById({
        cashBookId: this.$route.params.cashBookId,
        fetchLogs: true,
      })

      this.localSummaryCredit = this.summaryCreditsFormatted.map(c => {
        // TODO parametrizar dinherio
        if (c.origin === this.saleOriginEnum.SALE && c.paymentMethod.id === 1) {
          return {
            ...c,
            totalTyped: c.total,
            readOnly: true,
          }
        }
        return c
      })
      this.localSummaryDebit = this.summaryDebitsFormatted

      this.openingBalance = this.cashBookDetail.openingBalance || 0
    } catch (error) {
      this.showError({ error })
    } finally {
      this.hideLoadingOverlay()
    }
  },

  methods: {
    ...mapActions('pages/pdv', {
      stClosePdv: 'closePdv',
      stFetchCashBookDetailById: 'fetchCashBookDetailById',
    }),

    getCreditLabel(credit) {
      let nameOrigin = this.saleOriginEnumLabel[credit.origin]
      if (credit.origin === this.saleOriginEnum.BLEEDING) {
        nameOrigin = `Estorno de sangria`
      }
      return `${nameOrigin} [${credit.paymentMethod.name}]`
    },

    showStatementSidebar() {
      this.$refs.statementSidebar.show()
    },
  },
}
</script>

<style lang="scss" scoped>
.container-close {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
